export default {
  // siteName: "Tangible Investments, Inc",
  // siteIcon: "ion-flash",
  // footerText: `Tangible Investments, Inc @ ${new Date().getFullYear()}`,
  siteName: "Adanto Software",
  siteIcon: "ion-flash",
  footerText: `Adanto Software @ ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  apiUrl: "http://yoursite.com/api/",
  dashboard: "/dashboard",
  userApiUrl: process.env.REACT_APP_USER_BASE_URL,
};
