import actions from "./actions";

const initState = {};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.DATA_UPDATE:
      return {
        data: action.payload,
      };
    case actions.CLEAR_DATA:
      return initState;
    default:
      return state;
  }
}
