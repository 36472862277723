import { useState } from "react";

export default function useUser() {
    const getUser = () => {
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);
   // console.log(localStorage.user.permissions)
    return user;
  };

  const [user, setUser] = useState(getUser());

  const saveUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  const removeUser = () => {
    localStorage.removeItem("user");
  };

  return {
    removeUser: removeUser,
    setUser: saveUser,
    user: user,
  };
}
