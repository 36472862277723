const localDataName = "mateInvoice";
const orderStatusOptions = ["Pending", "Shipped", "Delivered"];

const today = new Date()
const newInvoice = {
  date: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
  po: "",
  term: "",
  sourceId: "",
  customerId: null,
  alternativeEmail: "",
  salePersonId: "",
  location: "3",
  locationId: "3",
  gradingService: "",
  gradingInvoiceNumber: "",
  gradingServiceType: "",
  paymentType: "",
  paymentNote: "",
  comment: "",
  internalComment: "",
  trackingInformation: "",
  subTotal: 0,
  quantityTotal: 0,
  currency: "$",
  type: "",
  taxTotal: 0,
  taxRate: 0,
  memoTermId: 2,
  removeCerts: false,
  lines: [
    {
      key: 1,
      itemName: "",
      itemCategoryId: 1,
      itemLocationId: 13,
      itemType: "unique",
      service: "",
      status: "",
      itemQuantity: 1,
      itemOwner: "TI",
      grade: "",
      itemRate: 0,
      itemAmount: 0,
      notes: "",
      description: "",
      isCoinLine: true,
      cert: "",
      excluded:"no"

    },
  ],
  gradingData:{
    itemGradingService: "",
    gradeInvoice: "",
    itemGradingType: "",
    gradingServiceId: "",
    gradingServiceTypeId: "",
    gradeCost: 0,
    owner: ''
  },
  shippingTotal: 0,
  adjustment: 0,
  grandTotal: 0,
  egp: 0,
};



const newCustomer = {
  customerCompany: null,
  customerFirstName: "",
  customerLastName: "",
  customerName: "",
  customerAddress: "",
  customerSecondAddress: "",
  customerCity: "",
  customerZip: "",
  customerStateId: null,
  customerStateName: "",
  customerCountryId: null,
  customerPhone: null,
  customerFax: null,
  customerEmail: null,
  customerSourceId: null,
  customerSalePersonId: null,
  customerSalePerson: null,
  customerTypeId: null,
  customerCce: null,
  customerEcommerceId: null,
  customerDriverLicence: null,
  customerTerms: null,
  customerPo: null,
  customerComments: null,
  customerHasNewsletter: false,
  customerIsActive: true,
  customerCreatedBy: '',
  customerCreatedByName: " ",
  createdAt: "",
  customerNote: ""
};

const createDemoData = () => {
  return newInvoice;
};

export {
  createDemoData,
  localDataName,
  newInvoice,
  orderStatusOptions,
  newCustomer
};
