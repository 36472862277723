import { combineReducers } from "redux";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import Ecommerce from "@iso/redux/ecommerce/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import Invoices from "@iso/redux/invoice/reducer";
import User from "@iso/redux/user/reducer";

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  Ecommerce,
  LanguageSwitcher,
  Invoices,
  User,
});
